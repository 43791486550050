import React from 'react';
import ErrorBoundary from '@components/tailwind/ErrorBoundary';
import {createRoot} from "react-dom/client";
import {lazyRetry} from "@utils/lazyRetry";

const LazyDebugger = React.lazy(() =>
    lazyRetry(
        () => import(/* webpackChunkName: "debugger" */ './components/Debugger'),
        'debugger'
    )
);

const rootEl = document.getElementById('module-debug');
if (rootEl) {
    createRoot(rootEl).render(
        <ErrorBoundary silent>
            <React.Suspense>
                <LazyDebugger/>
            </React.Suspense>
        </ErrorBoundary>
    );
}
