import React from 'react';
import MiniCart from './features/cart/MiniCart';
import ErrorBoundary from '@components/tailwind/ErrorBoundary';
import { createRoot } from 'react-dom/client';
import testStore from '@/store/testStore';
import { Provider } from 'react-redux';

const rootEl = document.getElementById('module-mini-cart');
if (rootEl) {
  createRoot(rootEl).render(
    <ErrorBoundary silent>
      <Provider store={testStore}>{React.createElement(MiniCart, rootEl.dataset)}</Provider>
    </ErrorBoundary>
  );
}
