import React from 'react';

const getStyle = () => {
  try {
    const svg = `
<svg xmlns='http://www.w3.org/2000/svg' viewBox="0 0 400 400" width="400" height="400">
    <text x="200" y="200" fill='#fff' font-family="Helvetica, Arial, sans-serif" font-size="30" dominant-baseline="central" text-anchor="middle">${window.DD_ENV} ${window.DD_VERSION}</text>
</svg>
`
      .replace(/[\r\n]/g, '')
      .replace(/[\s]+/g, ' ');

    const svgBase64 = btoa(svg);
    const svgUrl = 'data:image/svg+xml;base64,' + svgBase64;

    const style = `
  .dev-bg {
    background-image: url("${svgUrl}");
    background-position: 50% 50%;
    background-repeat: repeat-x;
    mix-blend-mode: difference;
    opacity: 0.25;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 100vh;
    pointer-events: none;
    z-index: 999999;
  }
`;
    return style;
  } catch (e) {
    console.error('Error generating dev watermark style', e);
    return null;
  }
};
const VersionWatermark = () => {
  if (window.DD_ENV === 'dev' && window.DD_VERSION !== 'dev') {
    const style = getStyle();
    return (
      <>
        <style type='text/css'>{style}</style>
        <div className={'dev-bg'}></div>
      </>
    );
  } else {
    return null;
  }
};

export default VersionWatermark;
