import { CustomizationChoiceType } from '../customization.data';
import { CustomShopCustomizationChoice } from './CustomShopCustomizationChoice';
import { DesignCollectionsCustomizationChoice } from './DesignCollectionsCustomizationChoice';
import { PersonalizationCustomizationChoice } from './PersonalizationCustomizationChoice';

export type CustomizeDropdownProps = {
  navCenterItemsClasses?: string;
};

export function CustomizationChoice({ type }: { type: CustomizationChoiceType }) {
  switch (type) {
    case CustomizationChoiceType.PERSONALIZATION:
      return <PersonalizationCustomizationChoice />;
    case CustomizationChoiceType.DESIGN_COLLECTIONS:
      return <DesignCollectionsCustomizationChoice />;
    case CustomizationChoiceType.CUSTOM_SHOP:
      return <CustomShopCustomizationChoice />;
    default:
      return null;
  }
}
