import { SmartyStreetsSDK, authIds, fieldKeys, isTruthy, updateInput, isError } from './common';

export function AddressValidator(hostName, key) {
  const authId =
    key ||
    Object.entries(authIds).find(function (e) {
      return new RegExp(e[0]).test(hostName);
    })[1];
  this._credentials = new SmartyStreetsSDK.core.SharedCredentials(authId, hostName);
  this._client = SmartyStreetsSDK.core.buildClient.usStreet(this._credentials);
  this._addresses = {};
}

AddressValidator.prototype.configureAddress = function (id, fields, maxCandidates, match) {
  if (!id) {
    throw new Error('missing id');
  }

  if (typeof fields !== 'object') {
    throw new Error('fields must be an object');
  }

  var fieldSelectors = {};
  fieldKeys.forEach(function (key) {
    const selector = fields[key];
    if (!selector) {
      return;
    }
    if (key === 'street2') {
      console.warn(
        '"street2" is for extra info (e.g. "Leave it on the porch"). Did you mean "secondary" (e.g. "Apt 52")?'
      );
    }

    fieldSelectors[key] = selector;
  });

  this._addresses[id] = {
    fields: fieldSelectors,
    maxCandidates: maxCandidates || 3,
    match: match,
    validation: {
      inputId: 0,
    },
  };
};

AddressValidator.prototype.validateAddress = function (inputId, addressInput, maxCandidates, match) {
  var lookup = new SmartyStreetsSDK.usStreet.Lookup();
  lookup.inputId = inputId;
  lookup.maxCandidates = maxCandidates;
  lookup.match = match;
  Object.entries(addressInput).forEach(function (e) {
    const field = e[0];
    if (field === 'street2') {
      console.warn(
        '"street2" is for extra info (e.g. "Leave it on the porch"). Did you mean "secondary" (e.g. "Apt 52")?'
      );
    }
    lookup[field] = e[1];
  });

  return this._client
    .send(lookup)
    .then(function (result) {
      return result.lookups[0];
    })
    .catch(function (error) {
      if (isError(error)) throw error;
      // If it's not an Error, make it one
      const e = isError(error.error) ? error.error : new Error('Failed to validate address');
      e.context = error;
      throw e;
    });
};

AddressValidator.prototype.validateAddressById = function (id) {
  const addressConfig = this._addresses[id];
  if (!addressConfig) {
    return new Promise(function (_, reject) {
      return reject(new Error('address id not found'));
    });
  }

  const addressInput = this.getCurrentAddressById(id);

  delete addressConfig.validation.candidates;
  delete addressConfig.validation.error;

  const inputId = ++addressConfig.validation.inputId;

  return this.validateAddress(inputId, addressInput, addressConfig.maxCandidates, addressConfig.match)
    .then(function (result) {
      // Ignore out of date responses
      if (inputId !== addressConfig.validation.inputId) {
        return;
      }

      addressConfig.validation.candidates = result.result;
      return {
        id: id,
        candidates: result.result,
      };
    })
    .catch(function (error) {
      // Ignore out of date responses
      if (inputId !== addressConfig.validation.inputId) {
        return;
      }

      addressConfig.validation.error = error;
      throw error;
    });
};
AddressValidator.prototype.updateAddressById = function (id, triggerChange, candidate) {
  const addressConfig = this._addresses[id];
  if (!addressConfig) {
    throw new Error('could not find address config for id: ' + id);
  }

  candidate = candidate || addressConfig.validation.accepted;
  if (!candidate) {
    throw new Error('no candidate supplied');
  }

  updateAddress(addressConfig.fields, candidate, triggerChange);
};
AddressValidator.prototype.getCandidatesByAddressId = function (id) {
  const addressConfig = this._addresses[id];
  if (!addressConfig) {
    throw new Error('could not find address config for id: ' + id);
  }
  const candidates = addressConfig.validation.candidates || [];
  return candidates.map(function (c) {
    c.address = addressFromCandidate(Object.keys(addressConfig.fields), c);
    return c;
  });
};
AddressValidator.prototype.acceptCandidate = function (id, candidate, triggerChange) {
  const addressConfig = this._addresses[id];
  if (!addressConfig) {
    throw new Error('could not find address config for id: ' + id);
  }
  if (typeof candidate === 'number') {
    candidate = addressConfig.validation.candidates[candidate];
  }

  addressConfig.validation.accepted = candidate;
  this.updateAddressById(id, triggerChange, candidate);
};
AddressValidator.prototype.getCurrentAddressById = function (id) {
  const addressConfig = this._addresses[id];
  if (!addressConfig) {
    throw new Error('could not find address config for id: ' + id);
  }

  const addressInput = {};
  Object.entries(addressConfig.fields).forEach(function (e) {
    const el = $(e[1]);
    addressInput[e[0]] = el.is('input[type="checkbox"]') ? el.is(':checked') : el.val();
  });
  return addressInput;
};

export const updateAddress = function (fields, candidate, triggerChange) {
  const f = Object.entries(fields);
  const isFreeform = f.length === 1;
  f.forEach(function (e) {
    const value = fieldValueFromCandidate(e[0], candidate, isFreeform);
    updateInput(e[1], value, triggerChange);
  });
};

export const addressFromCandidate = function (fields, candidate) {
  const isFreeform = fields.length === 1;
  const addr = {};
  fields.forEach(function (field) {
    addr[field] = fieldValueFromCandidate(field, candidate, isFreeform);
  });
  return addr;
};

export const fieldValueFromCandidate = function (field, candidate, isFreeform) {
  const addr = candidate.components;

  switch (field) {
    case 'street':
      return isFreeform
        ? [addr.deliveryLine1, addr.deliveryLine2, addr.lastLine].filter(isTruthy).join(', ')
        : [
            addr.primaryNumber,
            addr.streetPredirection,
            addr.streetName,
            addr.streetPostdirection,
            addr.streetSuffix,
          ]
            .filter(isTruthy)
            .join(' ');
    case 'secondary':
      return [
        [addr.extraSecondaryDesignator, addr.extraSecondaryNumber].filter(isTruthy).join(' '),
        [addr.secondaryDesignator, addr.secondaryNumber].filter(isTruthy).join(' '),
      ]
        .filter(isTruthy)
        .join(', ');
    case 'city':
      return addr.cityName;
    case 'state':
      return addr.state;
    case 'zipCode':
      return addr.zipCode;
    case 'lastLine':
      return addr.cityName + ', ' + addr.state + ' ' + addr.zipCode;
    case 'isResidential':
      return candidate.metadata.rdi === 'Residential';
  }
};
