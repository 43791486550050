import services from '@/services';
import { HeaderDropdownMenu } from '../../../components/tailwind/HeaderDropdownMenu';
import { gearDropdowns, navElement } from '../gear.data';

export type GearDropdownProps = {
  navCenterItemsClasses?: string;
};

export function GearDropdown({ navCenterItemsClasses }: GearDropdownProps) {
  return (
    <HeaderDropdownMenu name='Gear' portalElementId='headerDropdownPortal' className={navCenterItemsClasses}>
      <div className='tw-absolute tw-inset-x-0 tw-z-[999] tw-bg-white tw-text-black tw-shadow-md'>
        <div className='tw-mx-auto tw-grid tw-max-w-3xl tw-grid-cols-4 tw-gap-10 tw-py-4'>
          {gearDropdowns.map((dropdown) => (
            <div key={`${dropdown.title}-${dropdown.href}`} className='tw-max-w-[220px] tw-space-y-2.5'>
              <a
                href={dropdown.href}
                className='tw-mb-4 tw-block tw-w-full tw-border-b tw-border-[#C6C4BA] tw-font-body tw-text-sm tw-font-bold tw-text-neutrals-graphite !tw-no-underline hover:tw-text-neutrals-graphite'
                onClick={() => {
                  services.googleAnalytics.selectItemEvent(
                    { item_name: dropdown.title },
                    'main_nav',
                    'Main Nav'
                  );
                }}>
                {dropdown.title}
              </a>
              {dropdown.links.map((link) => (
                <a
                  key={`${link.title}-${link.href}`}
                  href={link.href}
                  className='tw-block tw-w-full tw-font-body tw-text-sm tw-text-neutrals-graphite hover:tw-text-neutrals-graphite hover:tw-underline'
                  onClick={() => {
                    services.googleAnalytics.selectItemEvent(
                      { item_name: link.title },
                      'main_nav',
                      'Main Nav'
                    );
                  }}>
                  {link.title}
                </a>
              ))}
            </div>
          ))}
          <div className='tw-min-w-[250px]'>
            <a
              href={navElement.href}
              className='tw-block tw-w-full tw-font-body tw-text-brand-black hover:tw-text-brand-black hover:!tw-no-underline'
              data-list-name='Main Nav'
              onClick={() => {
                services.googleAnalytics.selectItemEvent(
                  { item_name: navElement.title },
                  'main_nav',
                  'Main Nav'
                );
              }}>
              <img
                src={navElement.image.desktop}
                alt={navElement.image.alt}
                className='tw-w-full tw-rounded-[20px] tw-object-cover'
              />
              <div className='tw-mt-2.5 tw-text-xs tw-font-bold'>{navElement.title}</div>
              {'\n'}
              <p className='tw-text-xs'>{navElement.description}</p>
            </a>
          </div>
        </div>
      </div>
    </HeaderDropdownMenu>
  );
}
