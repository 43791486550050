import classNames from 'classnames';

import { designCollections } from '../customization.data';
import services from '@/services';

export function DesignCollectionsCustomizationChoice() {
  return (
    <div
      className={classNames('tw-col-span-2 tw-grid tw-h-full tw-gap-x-1 tw-py-0 tw-pr-2.5', {
        'tw-grid-cols-2': designCollections.length == 2 || designCollections.length,
        'tw-grid-cols-3': designCollections.length == 3,
        'tw-gap-y-3 tw-py-11': designCollections.length >= 4,
      })}>
      {designCollections.map((collection, index) => (
        <a
          key={index}
          href={collection.href}
          className='tw-flex'
          onClick={() => {
            services.googleAnalytics.selectItemEvent({ item_name: collection.name }, 'main_nav', 'Main Nav');
          }}>
          <img
            src={designCollections.length < 4 ? collection.thumbnail : collection.smallThumbnail}
            alt=''
            className='tw-h-full tw-object-contain'
          />
        </a>
      ))}
    </div>
  );
}
