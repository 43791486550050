import classNames from 'classnames';
import { hardCoolerLinks, navElement } from '../hard-coolers.data';
import services from '@/services';

export function HardCoolersNav() {
  return (
    <div className='tw-pl-6 tw-pr-[22px] tw-pb-16'>
      {hardCoolerLinks.map((choice, index) => (
        <a
          key={index}
          href={choice.href}
          onClick={() => {
            services.googleAnalytics.selectItemEvent({ item_name: choice.title }, 'main_nav', 'Main Nav');
          }}
          className={classNames(
            'tw-block tw-w-full tw-py-5 hover:tw-text-brand-black hover:!tw-no-underline',
            {
              'tw-border-b': index !== hardCoolerLinks.length - 1,
            }
          )}>
          <div className='tw-flex tw-items-center tw-justify-between'>
            <div>
              <div className='tw-flex tw-items-center tw-space-x-1 tw-text-lg tw-font-normal'>
                <div>{choice.title}</div>
              </div>
            </div>
          </div>
        </a>
      ))}
      <a
        href={navElement.href}
        className='tw-mt-4 tw-block tw-w-full tw-text-sm tw-text-brand-black hover:tw-text-brand-black hover:!tw-no-underline'
        onClick={() => {
          services.googleAnalytics.selectItemEvent(
            { item_name: navElement.titlePlainText },
            'main_nav',
            'Main Nav'
          );
        }}>
        <img
          src={navElement.image.mobile}
          alt={navElement.image.alt}
          className='tw-w-full tw-rounded-lg tw-object-cover'
        />
        <div className='tw-mt-2.5 tw-text-sm tw-font-bold'>{navElement.title}</div>
        {'\n'}
        <p className='tw-text-sm'>{navElement.description}</p>
      </a>
    </div>
  );
}
