import services from '@/services';
import { HeaderDropdownMenu } from '../../../components/tailwind/HeaderDropdownMenu';
import { hardCoolerLinks, navElement } from '../hard-coolers.data';

export type GearDropdownProps = {
  navCenterItemsClasses?: string;
};

export function HardCoolersDropdown({ navCenterItemsClasses }: GearDropdownProps) {
  return (
    <HeaderDropdownMenu
      name='Hard Coolers'
      portalElementId='headerDropdownPortal'
      className={navCenterItemsClasses}>
      <div className='tw-absolute tw-inset-x-0 tw-z-[999] tw-bg-white tw-font-body tw-text-black'>
        <div className='tw-mx-auto tw-flex tw-max-w-[820px] tw-py-5'>
          <div className='tw-mr-16'>
            <div className='tw-flex-1 tw-space-y-2.5'>
              <a
                href='/shop/coolers/hard-sided'
                className='tw-mb-4 tw-block tw-w-full tw-border-b tw-border-[#C6C4BA] tw-font-body tw-text-sm tw-font-bold tw-text-neutrals-graphite !tw-no-underline hover:tw-text-neutrals-graphite'
                onClick={() => {
                  services.googleAnalytics.selectItemEvent(
                    { item_name: 'Hard Coolers' },
                    'main_nav',
                    'Main Nav'
                  );
                }}>
                Hard Coolers
              </a>
              {hardCoolerLinks.map((link, index) => (
                <div key={index}>
                  <a
                    href={link.href}
                    className='tw-block tw-w-full tw-font-body tw-text-sm tw-text-neutrals-graphite hover:tw-text-neutrals-graphite hover:tw-underline'
                    onClick={() => {
                      services.googleAnalytics.selectItemEvent(
                        { item_name: link.title },
                        'main_nav',
                        'Main Nav'
                      );
                    }}>
                    {link.title}
                  </a>
                </div>
              ))}
            </div>
          </div>
          <div className='tw-ml-6 tw-min-w-[300px] tw-basis-0'>
            <a
              href={navElement.href}
              className='tw-block tw-w-full tw-text-brand-black hover:tw-text-brand-black hover:!tw-no-underline'
              onClick={() => {
                services.googleAnalytics.selectItemEvent(
                  { item_name: navElement.titlePlainText },
                  'main_nav',
                  'Main Nav'
                );
              }}>
              <img
                src={navElement.image.desktop}
                alt={navElement.image.alt}
                className='tw-max-w-[280px] tw-rounded-lg tw-object-cover'
              />
              <div className='tw-mt-2.5 tw-font-body tw-text-sm tw-font-bold'>{navElement.title}</div>
              <p className='tw-mb-0 tw-text-xs tw-leading-[14px]'>{navElement.description}</p>
            </a>
          </div>
        </div>
      </div>
    </HeaderDropdownMenu>
  );
}
