import customShopLogo from './images/CustomShop-Logo.svg';
import myRticLogo from './images/myRticLogo.svg';

export enum CustomizationChoiceType {
  PERSONALIZATION,
  DESIGN_COLLECTIONS,
  CUSTOM_SHOP,
}

export const customizationChoices = [
  {
    type: CustomizationChoiceType.CUSTOM_SHOP,
    icon: <img src={customShopLogo} className='tw-h-[11px]' />,
    title: 'Custom Shop',
    subtitle: 'Corporate Sales & Gifts',
    href: jsRoutes.controllers.Application.static('customization').url,
  },
  {
    type: CustomizationChoiceType.PERSONALIZATION,
    icon: <img src={myRticLogo} className='tw-h-[11px]' />,
    title: 'Personalization',
    subtitle: 'Personalized RTIC Gear For You',
    href: '/myrtic',
  },
  {
    type: CustomizationChoiceType.DESIGN_COLLECTIONS,
    title: 'Design Collections',
    subtitle: 'Officially Licensed Designs',
    href: '/myrtic',
    hasSubMenu: true,
  },
];

export const designCollections = [
  {
    name: 'Texas Rattlers Design Collection',
    logo: '',
    thumbnail: require('./images/Rattlers-image_d.jpg'),
    smallThumbnail: require('./images/rattlers image_m.jpg'),
    href: '/custom/texas-rattlers?category=Drinkware&asset=Ariat%20Texas%20Rattlers',
  },
  {
    name: 'NASCAR Design Collection',
    logo: require('./images/Nascar-Logo.png'),
    thumbnail: require('./images/NASCAR-image_d.jpg'),
    smallThumbnail: require('./images/NASCAR-image_m.jpg'),
    href: '/custom/nascar',
  },
];
