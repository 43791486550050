import { datadogRum } from '@datadog/browser-rum';
import CookieNames from './react-components/src/constants/cookie-names.constants';
import services from './react-components/src/services';

export const initDatadogRum = () => {
  const sessionSampleRate = 10;
  const sessionReplaySampleRate = 50; // Note this value is as a percentage of the session sample rate. So, if session sample rate = 10%, 50% = half of that 10%, or 5% of overall sessions.

  datadogRum.init({
    applicationId: 'd81169bd-e700-4d22-9c04-b1b2a888800b',
    clientToken: 'pub7363936427586769a24d02380d9855b5',
    site: 'datadoghq.com',
    service: window.DD_SERVICE,
    env: window.DD_ENV,
    version: window.DD_VERSION,
    sessionSampleRate: sessionSampleRate,
    sessionReplaySampleRate: sessionReplaySampleRate,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'allow',
    storeContextsAcrossPages: true,
  });

  datadogRum.startSessionReplayRecording();

  datadogRum.setUserProperty('sessionId', services.cookies.get(CookieNames.SESSION_ID));
  datadogRum.setUserProperty('name', services.cookies.get(CookieNames.NAME));
  datadogRum.setUserProperty('email', services.cookies.get(CookieNames.EMAIL));
};
