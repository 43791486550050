import { Disclosure, Transition } from '@headlessui/react';
import classNames from 'classnames';
import { Fragment } from 'react';
import {
  CustomizationChoiceType,
  customizationChoices,
  designCollections,
} from '../../customize-dropdown/customization.data';
import services from '@/services';

function DesignCollectionsSubMenu() {
  return (
    <div className='tw-flex tw-flex-col tw-space-y-3 tw-pb-2'>
      {designCollections.map((collection, index) => (
        <a
          key={index}
          href={collection.href}
          className='tw-block tw-w-full'
          onClick={() => {
            services.googleAnalytics.selectItemEvent({ item_name: collection.name }, 'main_nav', 'Main Nav');
          }}>
          <img src={collection.smallThumbnail} alt='' />
        </a>
      ))}
    </div>
  );
}

export function CustomizeNav() {
  const Submenu = ({ type }: { type: CustomizationChoiceType }) => {
    switch (type) {
      case CustomizationChoiceType.CUSTOM_SHOP:
      case CustomizationChoiceType.PERSONALIZATION:
        return null;
      case CustomizationChoiceType.DESIGN_COLLECTIONS:
        return <DesignCollectionsSubMenu />;
    }
  };

  return (
    <div className='tw-px-6'>
      {customizationChoices.map((choice, index) => (
        <Disclosure key={index}>
          {({ open }) => (
            <>
              <Disclosure.Button as={Fragment}>
                <a
                  href={choice.hasSubMenu ? '#' : choice.href ?? '#'}
                  className={classNames(
                    'tw-block tw-w-full tw-py-5 hover:tw-text-brand-black hover:!tw-no-underline',
                    {
                      'tw-border-b': index !== customizationChoices.length - 1 && !open,
                    }
                  )}
                  onClick={(e) => {
                    if (!choice.hasSubMenu) {
                      services.googleAnalytics.selectItemEvent(
                        { item_name: choice.title },
                        'main_nav',
                        'Main Nav'
                      );
                    }
                  }}>
                  <div className='tw-flex tw-items-center tw-justify-between'>
                    <div>
                      <div className='tw-flex tw-items-center tw-space-x-1 tw-text-lg tw-font-normal'>
                        {!!choice.icon && <div>{choice.icon}</div>}
                        <div>{choice.title}</div>
                      </div>
                      <div className='tw-text-xs'>{choice.subtitle}</div>
                    </div>
                    {choice.hasSubMenu && (
                      <div className='relative'>
                        <i
                          className={classNames('fa fa-chevron-down tw-transform tw-transition-transform', {
                            '-tw-scale-y-100': open,
                            'tw-scale-y-100': !open,
                          })}></i>
                      </div>
                    )}
                  </div>
                </a>
              </Disclosure.Button>
              <Transition
                show={open}
                className='tw-overflow-hidden tw-border-b'
                enter='tw-transition tw-transition-[max-height] tw-duration-300 tw-ease-in'
                enterFrom='tw-transform tw-max-h-0'
                enterTo='tw-transform tw-max-h-screen'
                leave='tw-transition tw-fransform tw-transition-[max-height] tw-duration-300 tw-ease-out'
                leaveFrom='tw-max-h-screen'
                leaveTo='tw-max-h-0'>
                <Disclosure.Panel static>
                  <Submenu type={choice.type} />
                </Disclosure.Panel>
              </Transition>
            </>
          )}
        </Disclosure>
      ))}
    </div>
  );
}
