import './globalInit.js';

import 'corejs-typeahead/dist/typeahead.jquery.js'; // Only need the UI jquery plug;

import './main.js';

import './react-components/src/MobileNavLinksRoot';
import './react-components/src/HeaderDropdownsRoot';
import './react-components/src/VersionWatermarkRoot';
import './react-components/src/GridElementsRoot';
import './react-components/src/MiniCartRoot';
import './react-components/src/DebugRoot';

window.siteId = 2;
