import { Transition } from '@headlessui/react';
import { useEffect, useMemo, useState } from 'react';
import { MobileNavSubNavType, NavLink, shopLinks, supportLinks } from '../mobile-nav.data';
import { CustomizeNav } from '../../customize-dropdown/components/CustomizeNav';
import { GearNav } from '../../gear-dropdown/components/GearNav';
import classNames from 'classnames';
import { HardCoolersNav } from '../../hard-coolers-dropdown/components/HardCoolersNav';
import services from '@/services';

const subMenuTypes = [
  MobileNavSubNavType.GEAR,
  MobileNavSubNavType.CUSTOM_SHOP,
  MobileNavSubNavType.HARD_COOLERS,
];

function SubNav({ type, onBack }: { type: MobileNavSubNavType; onBack: () => void }) {
  function SubNavHeader({ title, children }: { title: string; children: React.ReactNode }) {
    return (
      <div className='tw-w-full'>
        <button
          onClick={onBack}
          className='tw-flex tw-w-full tw-items-center tw-justify-start tw-bg-core-deep-navy tw-px-[25px] tw-py-[10px] tw-text-core-golden-yellow'>
          <i className='fa fa-chevron-left tw-text-white' />
          <div className='tw-ml-3 tw-text-sm tw-font-bold'>{title}</div>
        </button>
        <div className='tw-max-h-[89vh] tw-overflow-y-auto [&::-webkit-scrollbar]:tw-w-0'>{children}</div>
      </div>
    );
  }
  switch (type) {
    case MobileNavSubNavType.NONE:
      return null;
    case MobileNavSubNavType.GEAR:
      return (
        <SubNavHeader title='Gear'>
          <GearNav />
        </SubNavHeader>
      );
    case MobileNavSubNavType.CUSTOM_SHOP:
      return (
        <SubNavHeader title='Customize'>
          <CustomizeNav />
        </SubNavHeader>
      );
    case MobileNavSubNavType.HARD_COOLERS:
      return (
        <SubNavHeader title='Hard Coolers'>
          <HardCoolersNav />
        </SubNavHeader>
      );
    default:
      return null;
  }
}

export function MobileNavLinks({ nav: rawNav = '' }) {
  const nav = useMemo(() => {
    const nav: NavLink[] = JSON.parse(rawNav);
    const mapped = nav.map((n) => {
      const overrides = shopLinks.find((l) => l.key === n.key);
      return Object.assign({}, n, overrides);
    });
    return mapped;
  }, [rawNav]);

  const [subMenuType, setSubMenuType] = useState(MobileNavSubNavType.NONE);
  const [subMenuOpen, setSubMenuOpen] = useState(false);
  const [scrollY, setScrollY] = useState(0);

  useEffect(() => {
    const handleWrapperClick = () => {
      setSubMenuOpen(false);
    };
    document.querySelector('.plum-mob-menu-wrapper').addEventListener('click', handleWrapperClick);
    return () =>
      document.querySelector('.plum-mob-menu-wrapper').removeEventListener('click', handleWrapperClick);
  }, [setSubMenuType]);

  return (
    <div
      className={classNames('tw-z-10 tw-bg-white', {
        'tw-absolute tw-top-0 tw-left-0 tw-right-0 tw-bottom-0': !!subMenuOpen,
      })}>
      <Transition
        show={subMenuOpen}
        className='tw-transform tw-transition-transform'
        enter='tw-duration-300 tw-absolute tw-inset-x-0'
        enterFrom='tw-translate-x-full'
        enterTo='tw-translate-x-0'
        entered='tw-static'
        leave='tw-absolute tw-inset-x-0'
        leaveFrom='tw-translate-x-0'
        unmount={false}
        leaveTo='tw-translate-x-full'>
        {subMenuTypes.map((type, index) => (
          <div
            key={`subnav.${index}`}
            className={classNames('', {
              'tw-hidden': type !== subMenuType,
              'tw-block': type === subMenuType,
            })}>
            <SubNav type={subMenuType} onBack={() => setSubMenuOpen(false)} />
          </div>
        ))}
      </Transition>
      <Transition
        show={!subMenuOpen}
        className='tw-transform tw-transition-transform'
        enterFrom='-tw-translate-x-full'
        enterTo='tw-translate-x-0'
        leave='tw-duration-300'
        leaveFrom='tw-translate-x-0'
        leaveTo='-tw-translate-x-full'
        unmount={false}
        beforeLeave={() => {
          setScrollY(document.getElementById('mobileNavMenuPortal').scrollTop);
        }}
        beforeEnter={() => {
          document.getElementById('mobileNavMenuPortal').scrollTo({ top: scrollY });
        }}>
        <ul className='plum-mob-menu__menu-links'>
          {nav.map((link, index) => (
            <li key={index}>
              <a
                href={link.href}
                onClick={(e) => {
                  if (link.hasSubmenu) {
                    e.preventDefault();
                    setSubMenuType(link.submenuType);
                    setSubMenuOpen(true);
                  } else {
                    services.googleAnalytics.selectItemEvent(
                      { item_name: link.name },
                      'main_nav',
                      'Main Nav'
                    );
                  }
                }}
                className='tw-flex tw-flex-1 tw-items-center tw-justify-between'>
                <span>{link.name}</span>
                {link.hasSubmenu && (
                  <img
                    src={require('/public/images/rtic/rebranded/icon-arrow-right.svg')}
                    className='tw-max-h-full tw-w-full tw-max-w-[20px]'
                  />
                )}
              </a>
            </li>
          ))}
          {/* <li>
            <a href='#' onClick={() => setSubMenuOpen(true)}>
              <div className='tw-flex tw-flex-1 tw-items-center tw-justify-between'>
                <span>Customize</span>
                <i className='fa fa-chevron-right'></i>
              </div>
            </a>
          </li> */}
        </ul>
        <p className='plum-mob-menu__group-title'>Support</p>
        <ul className='plum-mob-menu__menu-links plum-mob-menu__menu-links--w-icons'>
          {supportLinks.map((link, index) => (
            <li key={index}>
              <a
                href={link.onClick ? '#' : link.href}
                onClick={(e) => {
                  services.googleAnalytics.selectContentEvent(link.ariaLabel);
                  if (typeof link.onClick === 'function') {
                    e.preventDefault();
                    link.onClick();
                  }
                }}>
                <div className='plum-mob-menu__img-wrapper'>
                  <span aria-label={link.ariaLabel} className={`plum-icon ${link.iconClassName}`}></span>
                </div>
                <span>{link.name}</span>
              </a>
            </li>
          ))}
        </ul>
      </Transition>
    </div>
  );
}
