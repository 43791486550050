export const navElement = {
  image: {
    desktop: require('./images/52 UL Wheeled desktop 2x.jpg'),
    mobile: require('./images/52 UL Wheeled mobile 2x.jpg'),
    alt: 'Meet the Ultra-Tough Wagon',
  },
  title: (
    <>
      Meet the 52<sup className='tw-top-[-3px]'>QT</sup> Ultra-Light Wheeled&nbsp;Cooler
    </>
  ),
  titlePlainText: 'Meet the 52QT Ultra-Light Wheeled Cooler',
  description: <>Take good times on the go with our all-terrain wheeled&nbsp;cooler.</>,
  href: '/52-QT-Ultra-Light-Wheeled-Cooler',
};

export const hardCoolerLinks = [
  {
    title: 'Ultra-Light Coolers',
    href: '/shop/coolers/hard-sided/ultra-light',
  },
  {
    title: 'Ultra-Tough Coolers',
    href: '/shop/coolers/hard-sided/original',
  },
  {
    title: 'Halftime Water Coolers',
    href: '/Halftime-Cooler',
  },
  {
    title: 'Accessories',
    href: '/shop/accessories/hard-cooler',
  },
  {
    title: 'Shop All',
    href: '/shop/coolers/hard-sided',
  },
];
