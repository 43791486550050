import React from 'react';
import {createRoot} from "react-dom/client";

import VersionWatermark from '@/components/VersionWatermark';

const rootEl = document.getElementById('version_watermark');

if (rootEl) {
  createRoot(rootEl).render(<VersionWatermark />);
}
