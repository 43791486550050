import { SmartyStreetsSDK, authIds, fieldKeys, isTruthy, updateInput, isError } from './common';

export function AddressAutocompleter(hostName, key) {
  const authId =
    key ||
    Object.entries(authIds).find(function (e) {
      return new RegExp(e[0]).test(hostName);
    })[1];
  this._credentials = new SmartyStreetsSDK.core.SharedCredentials(authId, hostName);

  this._client = SmartyStreetsSDK.core.buildClient.usAutocompletePro(this._credentials);
  this._addresses = {};
}

AddressAutocompleter.prototype.configureAddress = function (id, fields, maxSuggestions) {
  if (!id) {
    throw new Error('missing id');
  }

  if (typeof fields !== 'object') {
    throw new Error('fields must be an object');
  }

  var fieldSelectors = {};
  fieldKeys.forEach(function (key) {
    const selector = fields[key];
    if (!selector) {
      return;
    }
    if (key === 'street2') {
      console.warn(
        '"street2" is for extra info (e.g. "Leave it on the porch"). Did you mean "secondary" (e.g. "Apt 52")?'
      );
    }

    fieldSelectors[key] = selector;
  });

  this._addresses[id] = {
    fields: fieldSelectors,
    maxSuggestions: maxSuggestions || 5,
    requestId: 0,
    processing: false,
    error: undefined,
    suggestions: [],
    accepted: undefined,
  };
};

AddressAutocompleter.prototype.getSuggestions = function (address, maxSuggestions) {
  const search = [address.street, address.street2].filter(isTruthy).join(', ');
  if (!search) {
    return new Promise(function (accept, _) {
      return accept([]);
    });
  }

  var lookup = new SmartyStreetsSDK.usAutocompletePro.Lookup(search);
  lookup.maxSuggestions = maxSuggestions;
  lookup.preferCities = address.city && address.state ? [[address.city, address.state].join(', ')] : [];
  lookup.preferStates = [address.state].filter(isTruthy);
  lookup.preferGeolocation = 'city';

  return this._client
    .send(lookup)
    .then(function (response) {
      return response.result;
    })
    .catch(function (error) {
      if (isError(error)) throw error;
      // If it's not an Error, make it one
      const e = isError(error.error) ? error.error : new Error('Failed to get address suggestions');
      e.context = error;
      throw e;
    });
};

AddressAutocompleter.prototype.getSuggestionsById = function (id) {
  const addressConfig = this._addresses[id];
  if (!addressConfig) {
    return new Promise(function (_, reject) {
      return reject(new Error('address id not found'));
    });
  }

  const addressInput = this.getCurrentAddressById(id);

  addressConfig.accepted = undefined;
  addressConfig.suggestions = [];
  addressConfig.error = undefined;

  const requestId = ++addressConfig.requestId;

  return this.getSuggestions(addressInput, addressConfig.maxResults)
    .then(function (suggestions) {
      // Ignore out of date responses
      if (requestId !== addressConfig.requestId) {
        return;
      }

      addressConfig.suggestions = suggestions.map(function (s) {
        s.zipCode = s.zipcode;
        delete s.zipcode;
        return s;
      });
      return {
        id: id,
        requestId: requestId,
        suggestions: suggestions,
      };
    })
    .catch(function (error) {
      // Ignore out of date responses
      if (requestId !== addressConfig.requestId) {
        return;
      }

      addressConfig.error = error;
      throw error;
    });
};

AddressAutocompleter.prototype.getCurrentAddressById = function (id) {
  const addressConfig = this._addresses[id];
  if (!addressConfig) {
    throw new Error('could not find address config for id: ' + id);
  }

  const addressInput = {};
  Object.entries(addressConfig.fields).forEach(function (e) {
    const el = $(e[1]);
    addressInput[e[0]] = el.is('input[type="checkbox"]') ? el.is(':checked') : el.val();
  });
  return addressInput;
};

AddressAutocompleter.prototype.updateAddressById = function (id, triggerChange, suggestion) {
  const addressConfig = this._addresses[id];
  if (!addressConfig) {
    throw new Error('could not find address config for id: ' + id);
  }

  suggestion = suggestion || addressConfig.accepted;
  if (!suggestion) {
    throw new Error('no suggestion supplied');
  }

  updateAddress(addressConfig.fields, suggestion, triggerChange);
};

AddressAutocompleter.prototype.acceptSuggestion = function (id, suggestion, triggerChange) {
  const addressConfig = this._addresses[id];
  if (!addressConfig) {
    throw new Error('could not find address config for id: ' + id);
  }
  if (typeof suggestion === 'number') {
    suggestion = addressConfig.suggestions[suggestion];
  }

  addressConfig.accepted = suggestion;
  this.updateAddressById(id, triggerChange, suggestion);
};

export const updateAddress = function (fields, suggestion) {
  Object.entries(fields).forEach(function (e) {
    var field = e[0];
    if (field === 'street') field = 'streetLine';
    if (!(field in suggestion)) return;
    var value = suggestion[field];
    updateInput(e[1], value, true);
  });
};
