import SSSDK from 'smartystreets-javascript-sdk';

export const SmartyStreetsSDK = SSSDK;

export const authIds = {
  '\\.ngrok\\.io': '30238946554305422', // testing with https using ngrok.io
  '': '30238946505388637', // default
};

export const fieldKeys = [
  'street',
  'street2',
  'secondary',
  'city',
  'state',
  'zipCode',
  'lastLine',
  'isResidential',
];

export function isTruthy(i) {
  return !!i;
}

export function updateInput(selector, value, triggerChange) {
  var el = $(selector);
  if (!el.length) return;

  const isCheckbox = el.is('input[type="checkbox"]');
  const oldValue = isCheckbox ? el.prop('checked') : el.val();

  if (oldValue === value) return;

  isCheckbox ? el.prop('checked', value) : el.val(value);

  triggerChange && el.change();
}

/*
  Polyfill for Object.entries
  https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Object/entries#Polyfill
*/

if (!Object.entries) {
  Object.entries = function (obj) {
    var ownProps = Object.keys(obj),
      i = ownProps.length,
      resArray = new Array(i); // preallocate the Array
    while (i--) resArray[i] = [ownProps[i], obj[ownProps[i]]];

    return resArray;
  };
}

export function isError(e) {
  return e && e.message && e.stack;
}
