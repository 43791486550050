import classNames from 'classnames';
import { customizationChoices } from '../customization.data';

type CustomizationChoiceSelectorProps = {
  choice: typeof customizationChoices[number];
  index: number;
  selectedIndex: number;
  setSelectedIndex: React.Dispatch<React.SetStateAction<number>>;
};

export function CustomizationChoiceSelector({
  choice,
  index,
  selectedIndex,
  setSelectedIndex,
}: CustomizationChoiceSelectorProps) {
  return (
    <a
      key={index}
      href={choice.href ?? '#'}
      data-list-name='Main Nav'
      onClick={(e) => {
        window.CCMB.trackClick('select_item', e.currentTarget);
      }}
      className='hover:tw-text-black hover:!tw-no-underline'
      onMouseEnter={() => setSelectedIndex(index)}>
      <div className='tw-flex tw-items-center tw-justify-end tw-space-x-1'>
        {!!choice.icon && <div>{choice.icon}</div>}
        <div className='tw-text-[13px] tw-font-bold'>{choice.title}</div>{' '}
      </div>
      <div className='tw-text-right tw-text-xs'>{choice.subtitle}</div>
      <div
        className={classNames('tw-mt-[2px] tw-w-full tw-border-b tw-transition-all', {
          'tw-border-core-flame': index === selectedIndex,
          'tw-border-transparent': index !== selectedIndex,
        })}></div>
    </a>
  );
}
