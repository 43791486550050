import { combineReducers, configureStore } from '@reduxjs/toolkit';

import testsReducer from '@/reducers/tests.reducer';

const reducer = combineReducers({
  tests: testsReducer,
});

const testStore = configureStore({
  reducer: reducer,
  devTools: window.DD_ENV === 'dev',
  preloadedState: {},
});

export type AppState = ReturnType<typeof reducer>;
export type AppDispatch = typeof testStore.dispatch;
export default testStore;
