import React from 'react';
import { CustomizeDropdown } from './features/customize-dropdown/components/CustomizeDropdown';
import { GearDropdown } from './features/gear-dropdown/components/GearDropdown';
import { HardCoolersDropdown } from './features/hard-coolers-dropdown/components/HardCoolersDropdown';
import ErrorBoundary from '@components/tailwind/ErrorBoundary';
import {createRoot} from "react-dom/client";

const dropdowns = [
  {
    id: 'customizeDropdownRoot',
    component: CustomizeDropdown,
  },
  {
    id: 'gearDropdownRoot',
    component: GearDropdown,
  },
  {
    id: 'hardCoolersDropdownRoot',
    component: HardCoolersDropdown,
  },
];

dropdowns.forEach(({ id, component }) => {
  const rootEl = document.getElementById(id);

  if (rootEl) {
    createRoot(rootEl).render(
      <ErrorBoundary silent>{React.createElement(component, rootEl.dataset)}</ErrorBoundary>
    );
  }
});
