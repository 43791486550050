import { Disclosure, Transition } from '@headlessui/react';
import classNames from 'classnames';
import { Fragment } from 'react';
import { gearDropdowns, navElement } from '../gear.data';
import services from '@/services';

function SubMenuLinks({ links }: { links: { title: string; href: string }[] }) {
  return (
    <div className='tw-ml-5 tw-flex tw-flex-col tw-space-y-3 tw-pb-2'>
      {links.map((link, index) => (
        <a
          key={index}
          href={link.href}
          className='tw-block tw-w-full tw-py-3 tw-text-base tw-font-medium hover:tw-text-brand-black hover:!tw-no-underline'
          onClick={() => {
            services.googleAnalytics.selectItemEvent({ item_name: link.title }, 'main_nav', 'Main Nav');
          }}>
          {link.title}
        </a>
      ))}
    </div>
  );
}

export function GearNav() {
  return (
    <div className='tw-px-6'>
      {gearDropdowns.map((choice, index) => (
        <Disclosure key={index}>
          {({ open }) => (
            <>
              <Disclosure.Button as={Fragment}>
                <button
                  className={classNames(
                    'tw-block tw-w-full tw-py-5 hover:tw-text-brand-black hover:!tw-no-underline',
                    {
                      'tw-border-b': index !== gearDropdowns.length - 1 && !open,
                    }
                  )}>
                  <div className='tw-flex tw-items-center tw-justify-between'>
                    <div>
                      <div className='tw-flex tw-items-center tw-space-x-1 tw-text-lg tw-font-normal'>
                        <div>{choice.title}</div>
                      </div>
                    </div>
                    <div className='relative'>
                      <i
                        className={classNames('fa fa-chevron-down tw-transform tw-transition-transform', {
                          '-tw-scale-y-100': open,
                          'tw-scale-y-100': !open,
                        })}></i>
                    </div>
                  </div>
                </button>
              </Disclosure.Button>
              <Transition
                show={open}
                className='tw-overflow-hidden tw-border-b tw-pb-4'
                enter='tw-transition tw-transition-[max-height] tw-duration-300 tw-ease-in'
                enterFrom='tw-transform tw-max-h-0'
                enterTo='tw-transform tw-max-h-screen'
                leave='tw-transition tw-fransform tw-transition-[max-height] tw-duration-300 tw-ease-out'
                leaveFrom='tw-max-h-screen'
                leaveTo='tw-max-h-0'>
                <Disclosure.Panel static>
                  <SubMenuLinks links={choice.links} />
                </Disclosure.Panel>
              </Transition>
            </>
          )}
        </Disclosure>
      ))}
      <a
        href={navElement.href}
        className='tw-block tw-w-full tw-text-brand-black hover:tw-text-brand-black hover:!tw-no-underline'
        onClick={() => {
          services.googleAnalytics.selectItemEvent({ item_name: navElement.title }, 'main_nav', 'Main Nav');
        }}>
        <img
          src={navElement.image.mobile}
          alt={navElement.image.alt}
          className='tw-w-full tw-rounded-lg tw-object-cover'
        />
        <div className='tw-mt-2.5 tw-text-xs tw-font-bold'>{navElement.title}</div>
        <p className='tw-text-xs'>{navElement.description}</p>
      </a>
    </div>
  );
}
