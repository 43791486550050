import services from '@/services';
import customShopLogo from '../images/CustomShop-Logo.svg';

const customShopImage = require('../images/CustomShop-Image.jpg');

export function CustomShopCustomizationChoice() {
  const url = jsRoutes.controllers.Application.static('customization').url;

  return (
    <div className='tw-col-span-2 tw-grid tw-grid-cols-2'>
      <a
        href={url}
        className='tw-inline-flex tw-flex-col tw-items-start tw-justify-center tw-py-20 tw-px-4 hover:tw-text-brand-black hover:!tw-no-underline'
        onClick={() => {
          services.googleAnalytics.selectItemEvent({ item_name: 'Custom Shop' }, 'main_nav', 'Main Nav');
        }}>
        <div className='tw-flex tw-items-center tw-leading-none hover:tw-text-brand-black hover:!tw-no-underline'>
          <img src={customShopLogo} alt='myRticLogo' className='tw-h-[15px]' />
          <div className='tw-ml-1 tw-text-sm tw-font-bold tw-leading-none'>Custom Shop</div>
        </div>
        <div className='tw-mt-[2px] tw-text-[13px] tw-font-semibold'>Corporate Sales &amp; Gifts</div>
        <p className='tw-mb-0 tw-text-xs tw-leading-[18px]'>
          Show off your brand on a variety of products. Custom Shop offers bulk pricing, drop shipping, and
          more.
        </p>
      </a>
      <div className='tw-flex tw-flex-col'>
        <div className='tw-flex-1'>
          <a
            href={url}
            className='tw-h-full tw-w-full'
            onClick={() => {
              services.googleAnalytics.selectItemEvent(
                { item_name: 'Start Customizing' },
                'main_nav',
                'Main Nav'
              );
            }}>
            <img src={customShopImage} className='tw-h-full tw-w-full tw-object-cover' />
            <span className='tw-hidden'>Start Customizing</span>
          </a>
        </div>
        <div className='tw-flex tw-items-center tw-justify-center tw-bg-white tw-py-5'>
          <a
            href={url}
            className='tw-inline-flex tw-items-center tw-justify-center tw-rounded-md tw-border-2 tw-border-black tw-px-3 tw-py-[9px] tw-text-center tw-text-xs tw-font-bold tw-uppercase hover:tw-text-black hover:!tw-no-underline'
            onClick={() => {
              services.googleAnalytics.selectItemEvent(
                { item_name: 'Start Customizing' },
                'main_nav',
                'Main Nav'
              );
            }}>
            Start Customizing
          </a>
        </div>
      </div>
    </div>
  );
}
