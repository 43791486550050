export const apparelLinks = [
  {
    title: 'T-Shirts',
    href: '/shop/apparel/t-shirts',
  },
  {
    title: 'R-Tech Sun Protection',
    href: '/shop/apparel/sun-protection',
  },
  {
    title: 'Hoodies',
    href: '/shop/apparel/hoodies',
  },
  {
    title: 'Hats & Beanies',
    href: '/shop/apparel/hats-beanies',
  },
  {
    title: 'Shop All',
    href: '/shop/apparel',
  },
];

export const accessoriesLinks = [
  {
    title: 'Hard Cooler Accessories',
    href: '/shop/accessories/hard-cooler',
  },
  {
    title: 'Soft Cooler Accessories',
    href: '/shop/accessories/soft-cooler',
  },
  {
    title: 'Drinkware Accessories',
    href: '/shop/accessories/drinkware',
  },
  {
    title: 'Shop All',
    href: '/shop/accessories',
  },
];

export const outdoorGearLinks = [
  {
    title: 'Camp & Cookout',
    href: '/shop/camp-and-cookout/camp-cookout',
  },
  {
    title: 'Hard Cases',
    href: '/shop/camp-and-cookout/hard-cases',
  },
  {
    title: 'SUPs & Tubes',
    href: '/shop/camp-and-cookout/sup-tubes',
  },
  {
    title: 'Shop All',
    href: '/shop/camp-and-cookout',
  },
];

export const navElement = {
  image: {
    desktop: require('./images/meet the ultra-tough wagon image_d.jpg'),
    mobile: require('./images/meet the ultra-tough wagon image_m.jpg'),
    alt: 'Meet the Ultra-Tough Wagon',
  },
  title: 'Meet the Ultra-Tough Wagon',
  description: 'Keep your gear in tow.',
  href: '/Ultra-Tough-Wagon?color=Trailblazer',
};

export const gearDropdowns = [
  {
    title: 'Outdoor Gear',
    href: '/shop/camp-and-cookout',
    links: outdoorGearLinks,
  },
  {
    title: 'Apparel',
    href: '/shop/apparel',
    links: apparelLinks,
  },
  {
    title: 'Accessories',
    href: '/shop/accessories',
    links: accessoriesLinks,
  },
];
