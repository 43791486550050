import { useEffect, useRef, useState } from 'react';

import { HeaderDropdownMenu } from '../../../components/tailwind/HeaderDropdownMenu';
import { customizationChoices } from '../customization.data';
import { CustomizationChoice } from './CustomizationChoice';
import { CustomizationChoiceSelector } from './CustomizationChoiceSelector';

export type CustomizeDropdownProps = {
  navCenterItemsClasses?: string;
};

export function CustomizeDropdown({ navCenterItemsClasses }: CustomizeDropdownProps) {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const containerRef = useRef<HTMLDivElement>(null);
  const [offsetLeft, setOffsetLeft] = useState(0);
  const [buttonWidth, setMenuWidth] = useState(0);
  const [marginLeft, setMarginLeft] = useState(0);

  const resizeListener = () => {
    setTimeout(() => {
      const root = document.getElementById('customizeDropdownRoot');
      setOffsetLeft(root.offsetLeft);
      setMenuWidth(root.clientWidth);
      handleDropdownOpen(root.offsetLeft, root.clientWidth);
    }, 100);
  };
  useEffect(() => {
    resizeListener();
    window.addEventListener('resize', resizeListener);

    return () => {
      window.removeEventListener('resize', resizeListener);
    };
  }, []);

  const handleDropdownOpen = (_offsetLeft?: number, _buttonWidth?: number) => {
    if (!_offsetLeft) {
      _offsetLeft = offsetLeft;
    }
    if (!_buttonWidth) {
      _buttonWidth = buttonWidth;
    }
    setSelectedIndex(0);
    if (!containerRef.current) return;
    const containerWidth = containerRef.current?.clientWidth ?? 0;
    const _marginLeft = _offsetLeft - containerWidth + _buttonWidth;
    if (_marginLeft === marginLeft) return;
    setMarginLeft(_marginLeft);
  };

  return (
    <HeaderDropdownMenu
      name='Customize'
      portalElementId='headerDropdownPortal'
      onDropdownOpen={handleDropdownOpen}
      className={navCenterItemsClasses}>
      <div className='tw-absolute tw-inset-x-0 tw-z-[999] tw-flex tw-min-h-[280px] tw-justify-center tw-bg-white tw-text-neutrals-graphite tw-shadow-md'>
        <div
          ref={containerRef}
          style={{
            marginLeft,
          }}
          className='!tw-mx-auto tw-grid tw-max-w-3xl tw-flex-1 tw-grid-cols-3 tw-justify-center tw-font-body'>
          <CustomizationChoice type={customizationChoices[selectedIndex].type} />
          <div className='tw-h-full tw-bg-[#f5f5f5] tw-pr-[44px]'>
            <div className='tw-flex tw-flex-col tw-items-end tw-space-y-5 tw-py-5 tw-pb-20'>
              {customizationChoices.map((choice, index) => (
                <CustomizationChoiceSelector
                  key={index}
                  choice={choice}
                  index={index}
                  selectedIndex={selectedIndex}
                  setSelectedIndex={setSelectedIndex}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </HeaderDropdownMenu>
  );
}
