import * as Sentry from "@sentry/browser";
import { replayIntegration } from "@sentry/browser";
import { captureConsoleIntegration } from '@sentry/integrations';

export function initSentry() {
  Sentry.init({
    dsn: 'https://0768e7fddf634d71bdec0b1fbcc3ffb2@o48388.ingest.sentry.io/1236456',
    enabled: window.DD_ENV === 'prod',
    environment: window.DD_ENV === 'prod' ? 'production' : 'dev',

    // This sets the sample rate to be 10%. You may want this to be 100% while
    // in development and sample at a lower rate in production
    // GREG - making it zero (even 10% overloads quota)
    replaysSessionSampleRate: 0.0,

    // If the entire session is not sampled, use the below sample rate to sample
    // sessions when an error occurs.
    // GREG - reducing to 50%
    replaysOnErrorSampleRate: 0.5,

    integrations: [
      replayIntegration(),
      captureConsoleIntegration({
        levels: ['warn', 'error'],
      }),
    ],
    // Set tracesSampleRate to 1.0 to capture 10%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.1,
    ignoreErrors: [
      'The network connection was lost',
      /timeout/i,
      'Non-Error exception captured',
      'Non-Error promise rejection captured',
      'ResizeObserver loop limit exceeded',
      'affirm.js may not work properly due to the follow issue: Third Party Cookies not available',
      "Can't find variable: _AutofillCallbackHandler", // Known issue with Instagram WebView https://developers.facebook.com/community/threads/320013549791141/
    ],
    denyUrls: [
      // Chrome extensions
      /chrome-extension:\/\//i,
      /^chrome:\/\//i,
      // Safari Extension
      /safari-web-extension:\/\//i,
      /safari-extension:\/\//i,
      /attn.tv/i,
    ],
    allowUrls: [
      // Allow only errors from RTIC site (prevent showing third party errors)
      /rticoutdoors.com/i,
    ],
  });
}
