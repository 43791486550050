import classNames from 'classnames';
import { useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { IoChevronDown } from 'react-icons/io5';

export type CustomizeDropdownProps = {
  name: string;
  portalElementId: string;
  className?: string;
  children?: React.ReactNode;
  onDropdownOpen?: () => void;
};

const DROPDOWN_CLOSE_ON_MOUSEOUT_DELAY = 150; // milliseconds;

export function HeaderDropdownMenu({
  children,
  className,
  name,
  portalElementId,
  onDropdownOpen,
}: CustomizeDropdownProps) {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const mouseOutRef = useRef(false);

  const handleMouseEnter = () => {
    setDropdownOpen(true);
    typeof onDropdownOpen === 'function' && onDropdownOpen();
    mouseOutRef.current = false;
  };

  const handleMouseLeave = () => {
    mouseOutRef.current = true;
    const timeout = setTimeout(() => {
      if (mouseOutRef.current) setDropdownOpen(false);
    }, DROPDOWN_CLOSE_ON_MOUSEOUT_DELAY);
    return () => clearTimeout(timeout);
  };

  return (
    <>
      <button
        className={classNames(
          'tw-relative tw-font-body tw-text-base tw-font-normal tw-leading-snug',
          {
            'after:!tw-opacity-100': dropdownOpen,
          },
          className
        )}
        onMouseMove={handleMouseEnter}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}>
        <div className='tw-flex tw-items-center tw-gap-[2px]'>
          <span>{name}</span>
          <IoChevronDown size={14} />
        </div>
        {/* Dummy element to make hitbox bigger */}
        <div className='tw-absolute tw-inset-0 -tw-inset-y-6 tw-appearance-none' />
      </button>
      {createPortal(
        <div
          className={classNames('', { 'tw-hidden': !dropdownOpen, 'tw-block': dropdownOpen })}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}>
          {children}
        </div>,
        document.getElementById(portalElementId)
      )}
    </>
  );
}
