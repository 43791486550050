import React from 'react';
import ErrorBoundary from '@components/tailwind/ErrorBoundary';
import CustomShopBlock from '@features/shop/components/CustomShopBlock';
import FlagshipStore from '@features/shop/components/FlagshipStore';
import HomepageCTAs from '@features/shop/components/HomepageCTAs';
import ShopByCategory from '@features/shop/components/ShopByCategory';
import TheRealDeal from '@features/shop/components/TheRealDeal';
import {createRoot} from "react-dom/client";

const gridElements = [
  {
    id: 'gridElement_aboutUs',
    component: TheRealDeal,
  },
  {
    id: 'gridElement_customShopBlock',
    component: CustomShopBlock,
  },
  {
    id: 'gridElement_flagshipStore',
    component: FlagshipStore,
  },
  {
    id: 'gridElement_valueProp',
    component: HomepageCTAs,
  },
  {
    id: 'gridElement_categoryModule',
    component: ShopByCategory,
  },
];

gridElements.forEach(({ id, component }) => {
  const rootEl = document.getElementById(id);

  if (rootEl) {
    createRoot(rootEl).render(
      <ErrorBoundary silent>{React.createElement(component, rootEl.dataset)}</ErrorBoundary>
    );
  }
});
